<template>
  <section
    :style="{
      '--padding-inline': paddingInline,
      '--padding-block': paddingBlock,
    }">
    <slot />
  </section>
</template>

<script lang="ts" setup>
interface LayoutProps {
  paddingBlock?: string;
  paddingInline?: string;
}

const props = withDefaults(defineProps<LayoutProps>(), {
  paddingBlock: '0',
  paddingInline: '0',
});

const paddingBlock = computed(() => `${props.paddingBlock}px`);
const paddingInline = computed(() => `${props.paddingInline}px`);
</script>

<style lang="scss" scoped>
section {
  padding-inline: var(--padding-inline);
  padding-block: var(--padding-block);
  overflow: hidden;
}

section:empty {
  padding: 0;
}
</style>
